<template>
	<div class="system-content">
		<div class="top-box">
			<img class="exambg" src="@/assets/images/exambg.png" alt="">
			<div class="box1">
				<div class="box1-div1">
					<span class="fenshu">{{$route.query.userScore}}</span>
					<img src="@/assets/images/fenshu.png" alt="">
				</div>
				<div class="box1-div2">
					<p class="title1">您{{$route.query.passFlag == 2 ? '未'  : '已'}}通过此次考试</p>
					<p class="title2">此次考试共{{$route.query.examinAmount}}题，您答错{{$route.query.failAmount}}题</p>
				</div>
			</div>
		</div>
		<div class="bottom-box">
			<p class="title">您对此次考试是否满意</p>
			<div class="btn-box">
				<button :class="agreeFlag == '2' ? 'current' : ''" @click="agreeFlag = '2'">不满意</button>
				<button :class="agreeFlag == '1' ? 'current' : ''" @click="agreeFlag = '1'">满意</button>
			</div>
			<button class="submit-btn" @click="submit">提交</button>
		</div>
	</div>
</template>
<script>
export default {
	data() {
		return {
			agreeFlag: '1',
			id: ''
		}
	},
	created() {
		this.id = this.$route.query.examId;
	},
	methods: {
		submit() {
			let that = this;
			that.$request.post(
				"agreeExam",
				true,
				{
					id: that.id,
					agreeFlag: that.agreeFlag
				},
				function (r) {
					if (r.code == "0") {
						that.$router.push({
							name: 'onlineExam'
						})
					} else {
						that.$message.error(r.msg);
					}
				}
			);
		}
	},
	destroyed() {
		this.submit();
	}
}
</script>
<style lang="scss" scoped>
.system-content{
	box-sizing: border-box;
	padding-top: 20px;
	.top-box{
		position: relative;
		background-color: #fff;
		border-radius: 10px;
		box-sizing: border-box;
		padding-top: 25px;
		padding-bottom: 20px;
		.exambg{
			width: 100%;
		}
		.box1{
			position: absolute;
			left: 50%;
			margin-left: -220px;
			bottom: 100px;
			text-align: center;
			&>div{
				display: inline-block;
			}
			.box1-div1{
				margin-right: 15px;
				.fenshu{
					display: block;
					font-style: italic;
					font-size: 50px;
					font-weight: bold;
					color: rgba(202, 51, 51, 1);
					line-height: 40px;
				}
				img{
					width: 100px;
				}
			}
			.box1-div2{
				.title1{
					color: rgba(44, 44, 44, 1);
					font-size: 24px;
					font-weight: bold;
					text-align: left;
				}
				.title2{
					color: rgba(102, 102, 102, 1);
					font-size: 24px;
					font-weight: bold;
					text-align: left;
				}
			}
		}
	}
	.bottom-box{
		background-color: #fff;
		border-radius: 10px;
		margin-top: 15px;
		box-sizing: border-box;
		text-align: center;
		padding: 40px;
		.title{
			font-weight: bold;
			font-size: 20px;
			color: rgba(51, 51, 51, 1);
			margin-bottom: 10px;
		}
		.btn-box{
			margin-bottom: 10px;
			button{
				border: 1px solid rgba(226, 226, 226, 1);
				background-color: rgba(248, 251, 254, 1);
				cursor: pointer;
				outline: none;
				color: rgba(68, 68, 68, 1);
				border-radius: 3px;
				width: 95px;
				margin-right: 10px;
				height: 30px;
				line-height: 30px;
				text-align: center;
			}
			.current{
				color: rgba(17, 127, 252, 1);
				background-color: rgba(17, 127, 252, 0.2);
				border: none;
			}
		}
		.submit-btn{
			width: 138px;
			height: 32px;
			line-height: 32px;
			color: #fff;
			background-color: rgba(17, 127, 252, 1);
			border-radius: 3px;
			text-align: center;
			outline: none;
			border: none;
			cursor: pointer;
		}
	}
}
</style>
